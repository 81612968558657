import { LoadingButton } from '@mui/lab';
import { Button, Container, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { Formik, Form, ErrorMessage } from 'formik';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { REQ_STATUT } from '../../config/constants';
import { useCreerCategorieMutation, useModifierCategorieMutation } from '../../services';

const CategorieForm = ({ isEditing, dataCategorie, handleShowForm, reload }) => {
  const [creerCategorie] = useCreerCategorieMutation();
  const [modifierCategorie] = useModifierCategorieMutation();

  const [chargement, setChargement] = useState(false);

  const handleCreerCategorie = (values) => {
    setChargement(true);
    const data = values;

    creerCategorie(data)
      .unwrap()
      .then(
        (res) => {
          if (res.statut === REQ_STATUT.SUCCES) {
            reload();
            toast.success(res.message);
            handleShowForm({}, false);
            setChargement(false);
          } else {
            setChargement(false);
            toast.error(res.message);
          }
        },
        (err) => {
          setChargement(false);
          toast.error(err.error);
        }
      );
  };

  const handleModifierCategorie = (values) => {
    setChargement(true);
    const data = {
      ...values,
      categorie_id: dataCategorie?.id,
    };
    modifierCategorie(data)
      .unwrap()
      .then(
        (res) => {
          if (res.statut === REQ_STATUT.SUCCES) {
            reload();
            toast.success(res.message);
            handleShowForm({}, false);
            setChargement(false);
          } else {
            setChargement(false);
            toast.error(res.message);
          }
        },
        (err) => {
          setChargement(false);
          toast.error(err.error);
        }
      );
  };

  const CategorieSchema = Yup.object().shape({
    libelle_categorie: Yup.string().required('Obligatoire'),
  });

  return (
    <Container maxWidth="md">
      <Formik
        initialValues={{ libelle_categorie: isEditing === true ? dataCategorie.libelle_categorie : '' }}
        onSubmit={(values) => (isEditing === false ? handleCreerCategorie(values) : handleModifierCategorie(values))}
        validationSchema={CategorieSchema}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => (
          <Form>
            <Stack spacing={3}>
              <TextField
                required
                error={errors.libelle_categorie}
                name="libelle_categorie"
                label="Libellé catégorie"
                value={values.libelle_categorie}
                onChange={(e) => setFieldValue('libelle_categorie', e.target.value)}
                helperText={<ErrorMessage name="libelle_categorie" />}
              />

              {/* <Select>
            <InputLabel>État catégorie</InputLabel>
            <MenuItem value={0}>Actif</MenuItem>
            <MenuItem value={1}>Inactif</MenuItem>
          </Select> */}

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                sx={{ my: 4 }}
                disabled={chargement}
                loading={chargement}
              >
                {isEditing === true ? 'Modifier' : 'Enregistrer'}
              </LoadingButton>

              <Button variant="text" size="medium" onClick={handleShowForm} disabled={chargement}>
                Retour aux catégories
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default CategorieForm;
