import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthRedirecting = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate('/', { replace: true });
    }, 300);
  }, []);
  return <div>Rédirection vers la page de connexion pour vous authentifier...</div>;
};

export default AuthRedirecting;
