import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
// components
import { REQ_STATUT } from '../../../config/constants';
import useUser from '../../../hooks/useUser';
import { useLoginClientMutation } from '../../../services';
import { setAuthData } from '../../../slices';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user } = useUser();
  const [connexion] = useLoginClientMutation();

  const [chargement, setChargement] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClick = () => {
    navigate('/articles', { replace: true });
  };

  const LoginSchema = Yup.object().shape({
    login_user: Yup.string().required('Obligatoire'),
    password_user: Yup.string().required('Obligatoire'),
  });

  const handleConnexion = (values) => {
    setChargement(true);
    const data = values;

    connexion(data)
      .unwrap()
      .then(
        (res) => {
          if (res.statut === REQ_STATUT.SUCCES) {
            const data = { user: res.resultat, accessToken: res.accessToken };
            dispatch(setAuthData(data));
            setChargement(false);

            navigate('/articles', { replace: true });
          } else {
            setChargement(false);
            toast.error(res.message);
          }
        },
        (err) => {
          setChargement(false);
          toast.error(err.error);
        }
      );
  };

  useEffect(() => {
    if (user) {
      setChargement(true);
      setTimeout(() => {
        navigate('/articles', { replace: true });
      }, 500);
    }
  }, []);

  return (
    <Formik
      initialValues={{ login_user: '', password_user: '' }}
      onSubmit={(values) => handleConnexion(values)}
      validationSchema={LoginSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors }) => (
        <Form>
          <Stack spacing={3}>
            <TextField
              error={errors.login_user}
              name="login_user"
              label="Adresse e-mail / Nom d'utilisateur"
              value={values.login_user}
              onChange={(e) => setFieldValue('login_user', e.target.value)}
              disabled={chargement}
              helperText={<ErrorMessage name="login_user" />}
            />

            <TextField
              error={errors.password_user}
              name="password_user"
              label="Mot de passe"
              type="password"
              value={values.password_user}
              onChange={(e) => setFieldValue('password_user', e.target.value)}
              disabled={chargement}
              helperText={<ErrorMessage name="password_user" />}
            />
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            sx={{ my: 2 }}
            loading={chargement}
            disabled={chargement}
          >
            Connexion
          </LoadingButton>
        </Form>
      )}
    </Formik>
  );
}
