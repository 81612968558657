/* eslint-disable react/prop-types */
import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Container,
  FormControl,
  lighten,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { ErrorMessage, Formik } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { editorEmoji, REQ_STATUT } from '../../config/constants';
import { useCreerArticleMutation, useListeSousCategorieEditionQuery, useModifierArticleMutation } from '../../services';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '5px 10px',
  fontSize: '0.8rem',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.98),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const ArticleForm = ({ isEditing, dataArticle, handleShowForm, reload }) => {
  const [creerArticle] = useCreerArticleMutation();
  const [modifierArticle] = useModifierArticleMutation();

  const ArticleSchema = Yup.object().shape({
    reference_article: Yup.string().required('Obligatoire'),
    titre_article: Yup.string().required('Obligatoire'),
    sous_categorie_id: Yup.object().required('Obligatoire').typeError('Obligatoire'),
  });

  const {
    data: listeSousCategories,
    refetch: chargerSousCategories,
    isFetching: chargementSousCategories,
    isSuccess: chargementCategoriesOk,
  } = useListeSousCategorieEditionQuery();

  const [chargement, setChargement] = useState(false);

  const handleCreerArticle = (values) => {
    setChargement(true);
    const data = {
      ...values,
      sous_categorie_id: values.sous_categorie_id.id,
      contenu_article: draftToHtml(convertToRaw(values.contenu_article.getCurrentContent())),
    };

    creerArticle(data)
      .unwrap()
      .then(
        (res) => {
          if (res.statut === REQ_STATUT.SUCCES) {
            reload();
            toast.success(res.message);
            handleShowForm({}, false);
            setChargement(false);
          } else {
            setChargement(false);
            toast.error(res.message);
          }
        },
        (err) => {
          setChargement(false);
          toast.error(err.error);
        }
      );
  };

  const handleModifierArticle = (values) => {
    setChargement(true);
    const data = {
      ...values,
      sous_categorie_id: values.sous_categorie_id.id,
      contenu_article: draftToHtml(convertToRaw(values.contenu_article.getCurrentContent())),
      article_id: dataArticle?.id,
    };
    modifierArticle(data)
      .unwrap()
      .then(
        (res) => {
          if (res.statut === REQ_STATUT.SUCCES) {
            reload();
            toast.success(res.message);
            handleShowForm({}, false);
            setChargement(false);
          } else {
            setChargement(false);
            toast.error(res.message);
          }
        },
        (err) => {
          setChargement(false);
          toast.error(err.error);
        }
      );
  };

  useEffect(() => {
    chargerSousCategories();
  }, []);

  const uploadCallback = (file) =>
    new Promise((resolve, reject) => {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve({ data: { link: e.target.result } });
        };
        reader.readAsDataURL(file);
      }
    });

  return (
    <Container maxWidth="md">
      {chargementCategoriesOk ? (
        <Formik
          initialValues={{
            reference_article: isEditing === true ? dataArticle.reference_article : '',
            titre_article: isEditing === true ? dataArticle.titre_article : '',
            sous_categorie_id:
              isEditing === true
                ? find(
                    listeSousCategories && listeSousCategories?.resultat,
                    (item) => item.id === dataArticle.sous_categorie_id
                  )
                : null,
            contenu_article:
              isEditing === true
                ? EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      htmlToDraft(dataArticle.contenu_article).contentBlocks,
                      htmlToDraft(dataArticle.contenu_article).entityMap
                    )
                  )
                : EditorState.createEmpty(),
          }}
          onSubmit={(values) => (isEditing === false ? handleCreerArticle(values) : handleModifierArticle(values))}
          validationSchema={ArticleSchema}
        >
          {({ values, handleSubmit, setFieldValue, errors }) => (
            <Stack spacing={3}>
              <TextField
                required
                error={errors.reference_article}
                name="reference_article"
                label="Référence de l'article"
                value={values.reference_article}
                onChange={(e) => setFieldValue('reference_article', e.target.value)}
                helperText={<ErrorMessage name="reference_article" />}
              />

              <TextField
                required
                error={errors.titre_article}
                name="titre_article"
                label="Titre de l'article"
                value={values.titre_article}
                onChange={(e) => setFieldValue('titre_article', e.target.value)}
                helperText={<ErrorMessage name="titre_article" />}
              />

              <FormControl>
                <Autocomplete
                  options={listeSousCategories?.resultat ?? []}
                  loading={chargementSousCategories}
                  groupBy={(option) => option.libelle_categorie}
                  getOptionLabel={(option) => option.libelle_sous_categorie ?? ''}
                  value={values.sous_categorie_id}
                  onChange={(e, value) => setFieldValue('sous_categorie_id', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Sous-catégories"
                      error={errors.sous_categorie_id}
                      required
                      helperText={<ErrorMessage name="sous_categorie_id" />}
                    />
                  )}
                  renderGroup={(params) => (
                    <li>
                      <GroupHeader>{params.group}</GroupHeader>
                      <GroupItems>{params.children}</GroupItems>
                    </li>
                  )}
                />
              </FormControl>

              <Editor
                editorState={values.contenu_article}
                wrapperClassName="demo-wrapper"
                editorClassName="editor-container"
                onEditorStateChange={(value) => setFieldValue('contenu_article', value)}
                localization={{
                  locale: 'fr',
                }}
                toolbar={{
                  options: [
                    'inline',
                    'blockType',
                    'fontSize',
                    'fontFamily',
                    'list',
                    'textAlign',
                    'colorPicker',
                    'link',
                    // 'embedded',
                    'emoji',
                    'image',
                    'remove',
                    'history',
                  ],
                  image: {
                    uploadEnabled: true,
                    uploadCallback,
                    previewImage: true,
                    inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                    alt: { present: false, mandatory: false },
                    defaultSize: {
                      height: 'auto',
                      width: 'auto',
                    },
                  },
                  emoji: {
                    emojis: editorEmoji,
                  },
                }}
              />

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                sx={{ my: 4 }}
                disabled={chargement}
                loading={chargement}
              >
                {isEditing === true ? 'Modifier' : 'Enregister'}
              </LoadingButton>

              <Button variant="text" size="medium" onClick={handleShowForm} disabled={chargement}>
                Retour aux articles
              </Button>
            </Stack>
          )}
        </Formik>
      ) : (
        <Stack alignItems="center">
          <CircularProgress />
          <Typography>Veuillez patienter...</Typography>
        </Stack>
      )}
    </Container>
  );
};

export default ArticleForm;
