import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardPage from './pages/DashboardPage';
import ArticlesPage from './pages/ArticlesPage';
import CategoriesPage from './pages/CategoriesPage';
import SousCategoriesPage from './pages/SousCategoriesPage';
import AffectationsPage from './pages/AffectationsPage';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import AuthRedirecting from './sections/misc/AuthRedirection';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <LoginPage />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [{ path: '', element: <DashboardPage />, index: true }],
    },
    {
      path: '/articles',
      element: <DashboardLayout />,
      children: [{ path: '', element: <ArticlesPage />, index: true }],
    },
    {
      path: '/categories',
      element: <DashboardLayout />,
      children: [{ path: '', element: <CategoriesPage />, index: true }],
    },
    {
      path: '/sous-categories',
      element: <DashboardLayout />,
      children: [{ path: '', element: <SousCategoriesPage />, index: true }],
    },
    {
      path: '/affectations',
      element: <DashboardLayout />,
      children: [{ path: '', element: <AffectationsPage />, index: true }],
    },
    {
      path: "/error",
      element: <SimpleLayout />,
      children: [
        // { element: <Navigate to="/" />, index: true },
        { path: 'not-authed', element: <AuthRedirecting /> },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/error/404" replace />,
    },
    // {
    //   path: '/',
    //   element: <DashboardLayout />,
    //   children: [
    //     { element: <Navigate to="/articles" />, index: true },
    // { path: 'app', element: <DashboardAppPage /> },
    // { path: 'articles', element: <UserPage /> },
    // { path: 'categories', element: <UserPage /> },
    // { path: 'sous-categories', element: <UserPage /> },
    // { path: 'user', element: <UserPage /> },
    // { path: 'products', element: <ProductsPage /> },
    // { path: 'products', element: <ProductsPage /> },
    // { path: 'blog', element: <BlogPage /> },
    //   ],
    // },
    // {
    //   path: 'login',
    //   element: <LoginPage />,
    // },
  ]);

  return routes;
}
