/* eslint-disable react/prop-types */
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Container,
  Select,
  Stack,
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { ErrorMessage, Formik } from 'formik';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import * as Yup from 'yup';
import { REQ_STATUT } from '../../config/constants';
import {
  useCreerSousCategorieMutation,
  useListeCategorieQuery,
  useModifierSousCategorieMutation,
} from '../../services';

const SousCategorieForm = ({ isEditing, dataSousCategorie, handleShowForm, reload }) => {
  const [creerSousCategorie] = useCreerSousCategorieMutation();
  const [modifierSousCategorie] = useModifierSousCategorieMutation();

  // console.log(dataSousCategorie);

  const { data: listeCategories, refetch: chargerCategories } = useListeCategorieQuery();

  const [chargement, setChargement] = useState(false);

  const SousCategorieSchema = Yup.object().shape({
    libelle_sous_categorie: Yup.string().required('Obligatoire'),
    categorie_id: Yup.number().required('Obligatoire').typeError('Obligatoire'),
  });

  const handleCreerSousCategorie = (values) => {
    setChargement(true);
    const data = values;

    creerSousCategorie(data)
      .unwrap()
      .then(
        (res) => {
          if (res.statut === REQ_STATUT.SUCCES) {
            reload();
            toast.success(res.message);
            handleShowForm({}, false);
            setChargement(false);
          } else {
            setChargement(false);
            toast.error(res.message);
          }
        },
        (err) => {
          setChargement(false);
          toast.error(err.error);
        }
      );
  };

  const handleModifierSousCategorie = (values) => {
    setChargement(true);
    const data = {
      ...values,
      sous_categorie_id: dataSousCategorie?.id,
    };
    modifierSousCategorie(data)
      .unwrap()
      .then(
        (res) => {
          if (res.statut === REQ_STATUT.SUCCES) {
            reload();
            toast.success(res.message);
            handleShowForm({}, false);
            setChargement(false);
          } else {
            setChargement(false);
            toast.error(res.message);
          }
        },
        (err) => {
          setChargement(false);
          toast.error(err.error);
        }
      );
  };

  return (
    <Container maxWidth="md">
      <Formik
        initialValues={{
          libelle_sous_categorie: isEditing === true ? dataSousCategorie.libelle_sous_categorie : '',
          categorie_id: isEditing === true ? dataSousCategorie.categorie_id : null,
        }}
        onSubmit={(values) =>
          isEditing === false ? handleCreerSousCategorie(values) : handleModifierSousCategorie(values)
        }
        validationSchema={SousCategorieSchema}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => (
          <Stack spacing={3}>
            <TextField
              required
              error={errors.libelle_sous_categorie}
              name="libelle_sous_categorie"
              label="Libellé catégorie"
              value={values.libelle_sous_categorie}
              onChange={(e) => setFieldValue('libelle_sous_categorie', e.target.value)}
              helperText={<ErrorMessage name="libelle_sous_categorie" />}
            />

            {listeCategories && (
              <FormControl error={errors.categorie_id}>
                <InputLabel required>Catégorie</InputLabel>
                <Select
                  label="Catégorie"
                  onChange={(e) => setFieldValue('categorie_id', e.target.value)}
                  defaultValue={isEditing === true ? dataSousCategorie.categorie_id : ''}
                  is
                >
                  {listeCategories &&
                    listeCategories.map((catItem) => (
                      <MenuItem key={catItem.id} value={catItem.id}>
                        {catItem.libelle_categorie}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText>
                  <ErrorMessage name="categorie_id" />
                </FormHelperText>
              </FormControl>
            )}

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              sx={{ my: 4 }}
              disabled={chargement}
              loading={chargement}
            >
              Enregister
            </LoadingButton>

            <Button variant="text" size="medium" onClick={handleShowForm} disabled={chargement}>
              Retour aux sous-catégories
            </Button>
          </Stack>
        )}
      </Formik>
    </Container>
  );
};

export default SousCategorieForm;
