/* eslint-disable react/prop-types */
import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Container, FormControl, lighten, Stack, TextField } from '@mui/material';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { REQ_STATUT } from '../../config/constants';
import { useAffecterCategorieMutation, useListeTypeUtilisateurQuery } from '../../services';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '5px 10px',
  fontSize: '0.8rem',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.98),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

const AffectationModalForm = ({ isEditing, dataCategorie, handleShowForm }) => {
  const [affecterArticle] = useAffecterCategorieMutation();

  const {
    data: listeTypeUtilisateur,
    refetch: chargerTypeUtilisateur,
    isFetching: chargementTypeUtilisateur,
  } = useListeTypeUtilisateurQuery();

  const [chargement, setChargement] = useState(false);

  const AffectationSchema = Yup.object().shape({
    type_user_id: Yup.object().required('Obligatoire').typeError('Obligatoire'),
  });

  const handleAffecterCategorie = (values) => {
    setChargement(true);
    const data = {
      ...values,
      type_user_id: values.type_user_id.id,
    };

    affecterArticle(data)
      .unwrap()
      .then(
        (res) => {
          if (res.statut === REQ_STATUT.SUCCES) {
            toast.success(res.message);
            handleShowForm();
            setChargement(false);
          } else {
            setChargement(false);
            toast.error(res.message);
          }
        },
        (err) => {
          setChargement(false);
          toast.error(err.error);
        }
      );
  };

  useEffect(() => {
    chargerTypeUtilisateur();
  }, []);

  return (
    <Container maxWidth="md">
      <Formik
        initialValues={{
          type_user_id:
            isEditing === true
              ? find(
                  listeTypeUtilisateur && listeTypeUtilisateur?.resultat,
                  (item) => item.id === dataCategorie.sous_categorie_id
                )
              : null,
          categorie_id: dataCategorie && dataCategorie.id,
        }}
        onSubmit={(values) => handleAffecterCategorie(values)}
        validationSchema={AffectationSchema}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => (
          <Stack spacing={3} sx={{ mt: 4 }}>
            <FormControl>
              <Autocomplete
                options={listeTypeUtilisateur?.resultat ?? []}
                loading={chargementTypeUtilisateur}
                disabled={chargement}
                getOptionLabel={(option) => option.libelle_type ?? ''}
                value={values.type_user_id}
                onChange={(e, value) => setFieldValue('type_user_id', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Type utilisateur"
                    error={errors.type_user_id}
                    required
                    helperText={<ErrorMessage name="type_user_id" />}
                  />
                )}
              />
            </FormControl>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              sx={{ my: 4 }}
              disabled={chargement}
              loading={chargement}
            >
              {isEditing === true ? 'Modifier affectation' : 'Affecter la catégorie'}
            </LoadingButton>

            <Button variant="text" size="medium" onClick={handleShowForm} disabled={chargement}>
              Annuler et fermer
            </Button>
          </Stack>
        )}
      </Formik>
    </Container>
  );
};

export default AffectationModalForm;
