import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS } from "../config/endpoints";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: ENDPOINTS.BASE_URL,
  }),
  endpoints: (builder) => ({
    loginClient: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.CONNEXION,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useLoginClientMutation } = authApi;
