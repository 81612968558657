/* eslint-disable react/prop-types */
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ModalSuppression = ({
  visible,
  handleVisibility,
  handleConfirm,
  title,
  content,
  confirmLabel = 'Confirmer',
  cancelLabel = 'Annuler',
  chargement,
}) => (
  <Dialog
    open={visible}
    onClose={handleVisibility}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText>{content}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <LoadingButton
        variant="contained"
        loading={chargement}
        disabled={chargement}
        onClick={handleConfirm}
        sx={{
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
        autoFocus
      >
        {confirmLabel}
      </LoadingButton>
      <Button variant="text" onClick={handleVisibility}>
        {cancelLabel}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ModalSuppression;
