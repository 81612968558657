import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from '../config/endpoints';

export const affectationApi = createApi({
  reducerPath: 'affectationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENDPOINTS.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Affectations'],
  endpoints: (builder) => ({
    supprimerAffetation: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.SUPPRIMER_AFFECTATION,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Affectations', id: 'LISTE' }],
    }),
    listeAffectations: builder.query({
      query: () => ({
        url: ENDPOINTS.LISTE_AFFECTATIONS,
        method: 'GET',
      }),
      transformResponse: (response) => response.resultat,
      providesTags: [{ type: 'Affectations', id: 'LISTE' }],
    }),
    // modifierEtatArticle: builder.mutation({
    //   query: (data) => ({
    //     url: ENDPOINTS.MODIFIER_ETAT_ARTICLE,
    //     method: 'POST',
    //     body: data,
    //   }),
    // }),
  }),
});

export const { useLazyListeAffectationsQuery, useListeAffectationsQuery, useSupprimerAffetationMutation } =
  affectationApi;
