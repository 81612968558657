import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from '../config/endpoints';

export const articleApi = createApi({
  reducerPath: 'articleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENDPOINTS.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Articles'],
  endpoints: (builder) => ({
    creerArticle: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.CREER_ARTICLE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Articles', id: 'LISTE' }],
    }),
    modifierArticle: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.MODIFIER_ARTICLE,
        method: 'POST',
        body: data,
      }),
    }),
    supprimerArticle: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.SUPPRIMER_ARTICLE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Articles', id: 'LISTE' }],
    }),
    listeArticles: builder.query({
      query: () => ({
        url: ENDPOINTS.LISTE_ARTICLES,
        method: 'GET',
      }),
      transformResponse: (response) => response.resultat,
      providesTags: [{ type: 'Articles', id: 'LISTE' }],
    }),
    modifierEtatArticle: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.MODIFIER_ETAT_ARTICLE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Articles', id: 'LISTE' }],
    }),
  }),
});

export const {
  useCreerArticleMutation,
  useModifierArticleMutation,
  useModifierEtatArticleMutation,
  useSupprimerArticleMutation,
  useListeArticlesQuery,
  useLazyListeArticlesQuery,
} = articleApi;
