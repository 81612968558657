import { Navigate } from 'react-router-dom';
import useUser from '../hooks/useUser';
  
const PrivateContent = ({ Component }) => {
  const { user } = useUser();

  if (typeof user !== 'undefined' && user !== null) {
    return <Component />;
  }
  return <Navigate to="/error/not-authed" />;
};

const withAuth = (Component) => () => <PrivateContent Component={Component} />;

export default withAuth;
