import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from '../config/endpoints';

export const utilisateurApi = createApi({
  reducerPath: 'utilisateurApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENDPOINTS.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  refetchOnFocus: true,
  endpoints: (builder) => ({
    listeTypeUtilisateur: builder.query({
      query: () => ({
        url: ENDPOINTS.LISTE_TYPE_UTILISATEUR,
        method: 'GET',
      }),
    }),
    // modifierEtatArticle: builder.mutation({
    //   query: (data) => ({
    //     url: ENDPOINTS.MODIFIER_ETAT_ARTICLE,
    //     method: 'POST',
    //     body: data,
    //   }),
    // }),
  }),
});

export const { useListeTypeUtilisateurQuery } = utilisateurApi;
