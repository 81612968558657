export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const ENDPOINTS = {
  // Authentification
  BASE_URL: API_BASE_URL,
  CONNEXION: 'user/login-admin',

  // Articles
  CREER_ARTICLE: 'user/ajouter-article',
  MODIFIER_ARTICLE: 'user/modifier-article',
  MODIFIER_ETAT_ARTICLE: 'user/modifier-etat-article',
  SUPPRIMER_ARTICLE: 'user/supprimer-article',
  LISTE_ARTICLES: 'user/liste-article',

  // Catégories
  CREER_CATEGORIE: 'user/ajouter-categorie',
  MODIFIER_CATEGORIE: 'user/modifier-categorie',
  MODIFIER_ETAT_CATEGORIE: 'user/modefier-etat-categorie',
  SUPPRIMER_CATEGORIE: 'user/supprimer-categorie',
  LISTE_CATEGORIES: 'user/liste-categorie',
  AFFECTER_CATEGORIE: 'user/affectation-categorie-type-utilisateur',

  // Sous Catégories
  CREER_SOUS_CATEGORIE: 'user/ajouter-sous-categorie',
  MODIFIER_SOUS_CATEGORIE: 'user/modifier-sous-categorie',
  MODIFIER_ETAT_SOUS_CATEGORIE: 'user/modifier-etat-sous-categorie',
  SUPPRIMER_SOUS_CATEGORIE: 'user/supprimer-sous-categorie',
  LISTE_SOUS_CATEGORIES: 'user/liste-sous-categorie',
  LISTE_SOUS_CATEGORIES_EDITION: 'user/liste-sous-categorie-pour-operation',

  // Utilisateurs
  LISTE_TYPE_UTILISATEUR: 'user/liste-type-utilisateur',
  
  // Affectations
  LISTE_AFFECTATIONS: 'user/liste-affectation',
  SUPPRIMER_AFFECTATION: 'user/modifier-etat-affectation',
};
