import { createSlice } from '@reduxjs/toolkit';
// import jwt_decode from "jwt-decode";

export const authSlice = createSlice({
  name: 'auth',
  initialState: { user: null, accessToken: null },
  reducers: {
    setAuthData: (state, { payload }) => {
      state.accessToken = payload.accessToken;
      state.user = payload.user;
      // state.user = jwt_decode(payload.user);
    },
    removeAuthData: (state, payload) => {
      state.user = null;
      state.accessToken = null;
    },
  },
});

export const { setAuthData, removeAuthData } = authSlice.actions;
export default authSlice.reducer;
