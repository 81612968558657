// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}${'.svg'}`} sx={{ width: 1, height: 1 }} />;
const navConfig = [
  // {
  //   title: 'tableau de bord',
  //   path: '/dashboard',
  //   icon: icon('chart-pie-solid'),
  // },
  {
    title: 'catégories',
    path: '/categories',
    icon: icon('layer-group-solid'),
  },
  {
    title: 'sous-catégories',
    path: '/sous-categories',
    icon: icon('folder-tree-solid'),
  },
  {
    title: 'articles',
    path: '/articles',
    icon: icon('newspaper-solid'),
  },
  {
    title: 'affectations',
    path: '/affectations',
    icon: icon('link-solid'),
  },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },
  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
