export const REQ_STATUT = {
  SUCCES: 'succes',
  ECHEC: 'erreur',
};

export const TYPE_ETAT = {
  ACTIVER: 'activer',
  DESACTIVER: 'Désactiver',
};

export const editorEmoji = [
  '😀',
  '😁',
  '😂',
  '😃',
  '😉',
  '😋',
  '😎',
  '😍',
  '😗',
  '🤗',
  '🤔',
  '😣',
  '😫',
  '😴',
  '😌',
  '🤓',
  '😛',
  '😜',
  '😠',
  '😇',
  '😷',
  '😈',
  '👻',
  '😺',
  '😸',
  '😹',
  '😻',
  '😼',
  '😽',
  '🙀',
  '🙈',
  '🙉',
  '🙊',
  '👼',
  '👮',
  '🕵',
  '💂',
  '👳',
  '🎅',
  '👸',
  '👰',
  '👲',
  '🙍',
  '🙇',
  '🚶',
  '🏃',
  '💃',
  '⛷',
  '🏂',
  '🏌',
  '🏄',
  '🚣',
  '🏊',
  '⛹',
  '🏋',
  '🚴',
  '👫',
  '💪',
  '👈',
  '👉',
  '👉',
  '👆',
  '🖕',
  '👇',
  '🖖',
  '🤘',
  '🖐',
  '👌',
  '👍',
  '👎',
  '✊',
  '👊',
  '👏',
  '🙌',
  '🙏',
  '🐵',
  '🐶',
  '🐇',
  '🐥',
  '🐸',
  '🐌',
  '🐛',
  '🐜',
  '🐝',
  '🍉',
  '🍄',
  '🍔',
  '🍤',
  '🍨',
  '🍪',
  '🎂',
  '🍰',
  '🍾',
  '🍷',
  '🍸',
  '🍺',
  '🌍',
  '🚑',
  '⏰',
  '🌙',
  '🌝',
  '🌞',
  '⭐',
  '🌟',
  '🌠',
  '🌨',
  '🌩',
  '⛄',
  '🔥',
  '🎄',
  '🎈',
  '🎉',
  '🎊',
  '🎁',
  '🎗',
  '🏀',
  '🏈',
  '🎲',
  '🔇',
  '🔈',
  '📣',
  '🔔',
  '🎵',
  '🎷',
  '💰',
  '🖊',
  '📅',
  '✅',
  '❎',
  '💯',
];

export const defaultRowPerPage = 100;
export const valeurPagination = [50, 100, 200];
