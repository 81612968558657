import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from '../config/endpoints';

export const categorieApi = createApi({
  reducerPath: 'categorieApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENDPOINTS.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  // refetchOnFocus: true,
  tagTypes: ['Categories'],
  endpoints: (builder) => ({
    creerCategorie: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.CREER_CATEGORIE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Categories', id: 'LISTE' }],
    }),
    modifierCategorie: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.MODIFIER_CATEGORIE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Categories', id: 'LISTE' }],
    }),
    supprimerCategorie: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.SUPPRIMER_CATEGORIE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Categories', id: 'LISTE' }],
    }),
    listeCategorie: builder.query({
      query: () => ({
        url: ENDPOINTS.LISTE_CATEGORIES,
        method: 'GET',
      }),
      transformResponse: (response) => response.resultat,
      providesTags: [{ type: 'Categories', id: 'LISTE' }],
    }),
    modifierEtatCategorie: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.MODIFIER_ETAT_CATEGORIE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Categories', id: 'LISTE' }],
    }),
    affecterCategorie: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.AFFECTER_CATEGORIE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'Categories', id: 'LISTE' }],
    }),
  }),
});

export const {
  useCreerCategorieMutation,
  useModifierCategorieMutation,
  useModifierEtatCategorieMutation,
  useSupprimerCategorieMutation,
  useListeCategorieQuery,
  useLazyListeCategorieQuery,
  useAffecterCategorieMutation,
} = categorieApi;
