import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from '../config/endpoints';

export const sousCategorieApi = createApi({
  reducerPath: 'sousCategorieApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ENDPOINTS.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.accessToken;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  // refetchOnFocus: true,
  tagTypes: ['SousCategories', 'SousCategoriesEdition'],
  endpoints: (builder) => ({
    creerSousCategorie: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.CREER_SOUS_CATEGORIE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'SousCategories', id: 'LISTE' }],
    }),
    modifierSousCategorie: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.MODIFIER_SOUS_CATEGORIE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'SousCategories', id: 'LISTE' }],
    }),
    supprimerSousCategorie: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.SUPPRIMER_SOUS_CATEGORIE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'SousCategories', id: 'LISTE' }],
    }),
    listeSousCategorie: builder.query({
      query: () => ({
        url: ENDPOINTS.LISTE_SOUS_CATEGORIES,
        method: 'GET',
      }),
      transformResponse: (response) => response.resultat,
      providesTags: [{ type: 'SousCategories', id: 'LISTE' }],
    }),
    modifierEtatSousCategorie: builder.mutation({
      query: (data) => ({
        url: ENDPOINTS.MODIFIER_ETAT_SOUS_CATEGORIE,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [{ type: 'SousCategories', id: 'LISTE' }],
    }),
    listeSousCategorieEdition: builder.query({
      query: () => ({
        url: ENDPOINTS.LISTE_SOUS_CATEGORIES_EDITION,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useCreerSousCategorieMutation,
  useModifierSousCategorieMutation,
  useSupprimerSousCategorieMutation,
  useModifierEtatSousCategorieMutation,
  useListeSousCategorieQuery,
  useLazyListeSousCategorieQuery,
  useListeSousCategorieEditionQuery,
} = sousCategorieApi;
